<template>
  <section id="gallery" class="blog">
      <!-- header of section -->
      <h1>Preporuka našeg šefa</h1>
      <!-- blog items -->
      <div class="imageWrapper">
        <div
          v-for="item in galleryItems"
          :key="item.id"
          class=""
        >
          <div class="item animated wow fadeIn">
            <img class="image" :src="item.url" />
            <div class="overlay title-overlay">
              <div class="text">{{ item.hoovertext }}</div>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      galleryItems: [
        {
          id: 1,
          url: require("@/assets/images/food2/eggbacon.jpeg"),
          hoovertext: "Jaja na oko",
        },
        {
          id: 2,
          url: require("@/assets/images/food2/boiledegg1.jpeg"),
          hoovertext: "Velvet doručak",
        },
        {
          id: 3,
          url: require("@/assets/images/food2/eggsausage.jpeg"),
          hoovertext: "Kajgana",
        },
        {
          id: 4,
          url: require("@/assets/images/food2/sandwiche1.jpeg"),
          hoovertext: "Klub sendvič",
        },
        {
          id: 5,
          url: require("@/assets/images/food2/bruschetta1.jpeg"),
          hoovertext: "Brusketi sir pršuta",
        },
        {
          id: 6,
          url: require("@/assets/images/food2/appitezers.jpeg"),
          hoovertext: "Carpaccio",
        },

        {
          id: 7,
          url: require("@/assets/images/food/chicken1.jpg"),
          hoovertext: "Piletina sa spanaćem",
        },
        {
          id: 8,
          url: require("@/assets/images/food/food2.jpg"),
          hoovertext: "Junetina sa pire krompirom",
        },

        {
          id: 9,
          url: require("@/assets/images/food/mix.jpg"),
          hoovertext: "Ćureći file sa pršutom",
        },
        {
          id: 10,
          url: require("@/assets/images/food2/salmon1.jpeg"),
          hoovertext: "Losos steak",
        },
        {
          id: 11,
          url: require("@/assets/images/food2/salad2.jpeg"),
          hoovertext: "Biftek salata",
        },

        {
          id: 12,
          url: require("@/assets/images/food2/chickenSalad.jpeg"),
          hoovertext: "Velvet salata",
        },
        {
          id: 13,
          url: require("@/assets/images/food/soup.jpg"),
          hoovertext: "Pikantna Meksička čorba",
        },
        {
          id: 14,
          url: require("@/assets/images/food2/pasta3.jpeg"),
          hoovertext: "Bolognese",
        },
        {
          id: 15,
          url: require("@/assets/images/food2/carbonara.jpeg"),
          hoovertext: "Carbonara",
        },
        {
          id: 16,
          url: require("@/assets/images/food2/risoto1.jpeg"),
          hoovertext: "Rižoto sa piletinom",
        },
        {
          id: 17,
          url: require("@/assets/images/food2/pizza5.jpeg"),
          hoovertext: "Capricciosa",
        },
        {
          id: 18,
          url: require("@/assets/images/food2/pizza6.jpeg"),
          hoovertext: "Toscana",
        },
        {
          id: 19,
          url: require("@/assets/images/food/food13.jpg"),
          hoovertext: "Nutela roll",
        },
        {
          id: 20,
          url: require("@/assets/images/food/sweet6.jpg"),
          hoovertext: "Velvet sutlijaš",
        },
        {
          id: 21,
          url: require("@/assets/images/food/sweet1.jpeg"),
          hoovertext: "Nutella cake",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
  .imageWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .item {
    max-width: 400px;
    height: auto;
  }

  #gallery {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 10px;
  }
</style>
