<template>
  <footer class="w-100 py-0 flex-shrink-0">
    <div class="container py-4">
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <h5 class="text-white mb-3">Radno vreme</h5>
          <ul class="list-unstyled text-muted">
            <li>Ponedeljak - Četvrtak 07:30-23:00</li>
            <li>Petak - Subota 07:30-00:00</li>
            <li>Nedelja 07:30-23:00</li>
          </ul>
          <div class="row">
            <div class="col">
              <h5>My pub</h5>
              <address>
                Puškinova 40,<br />
                21000 Novi Sad<br />
                Srbija.
              </address>
            </div>
            <div class="col">
              <h5>Coffee & kitchen</h5>
              <address>
                Alekse Šantića 23,<br />
                21000 Novi Sad<br />
                Srbija.
              </address>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <router-link to="/">
            <img src="@/assets/images/velvet-logo.png" alt=""
          /></router-link>
        </div>
        <div class="col-lg-4 col-md-12">
          <h5 class="text-white mb-3">Kontakt</h5>
          <div class="contact">
            <h6>Telefon:</h6>
            <a href="tel:+ 381 60/6162-635">+381 60/6162-635</a> <br />
            <a href="tel:+ 381 62/555-444">+381 62/555-444</a>
          </div>
          <h6>Pratite nas:</h6>
          <social-media />
        </div>
      </div>
    </div>
    <p class="small text-muted">
      Copyrights &copy; Velvet NS <span>powered by </span>
      <a class="text-primary" href="#">MJcoding</a>
    </p>
  </footer>
</template>

<script>
import SocialMedia from "../layout/SocialMedia.vue";
export default {
  name: "Footer",
  components: {
    SocialMedia,
  },
};
</script>

<style></style>
