<template>
  <b-container id="about" fluid class="py-1">
    <b-row>
      <b-col md="6" class="first-col">
        <h1>Velvet Novi Sad</h1>
        <hr />
        <div class="col">
          <h3>Coffee & kitchen</h3>
          <address>
            Alekse Šantića 23<br />
            21000 Novi Sad<br />
            Srbija
          </address>
        </div>
        <div class="col">
          <h3>My pub</h3>
          <address>
            Puškinova 40<br />
            21000 Novi Sad<br />
            Srbija
          </address>
        </div>
        <h5>Radno vreme</h5>
        <ul class="list-unstyled text-muted">
          <li>Ponedeljak - Četvrtak 07:30-23:00</li>
          <li>Petak - Subota 7:30-00:00</li>
          <li>Nedelja 07:30-23:00</li>
        </ul>
        <div class="contact">
          <h5>Kontakt:</h5>
          <a href="tel:+ 381 60/6162-635">+381 60/6162-635</a><br />
          <a href="tel:+ 381 62/555-444">+381 62/555-444</a>
        </div>
        <social-media />
      </b-col>
      <b-col class="md-6">
        <h1>Naša priča</h1>
        <hr />
        <p class="px-4 about d-none d-lg-block d-xl-block">
          {{ text }}
        </p>
        <p class="px-4 pt-3 about d-xl-none d-lg-none">
          {{ formattedText }}

          <a class="" style="color: blue" @click="showingText = !showingText">
            Pročitaj {{ showingText ? "Manje" : "Više" }}
          </a>
        </p>
        <img class="dark-logo" src="@/assets/images/logo-dark.png" alt="" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SocialMedia from "../layout/SocialMedia.vue";

export default {
  components: { SocialMedia },
  data() {
    return {
      showingText: false,

      text: "Priča našeg restorana potiče od zime 2016. godine, kada smo prvi put došli na ovu lokaciju. Gledajući sam lokal i sve oko lokala, sve je izgledalo vrlo neugledno. Tada niko nije mogao ni pretpostaviti da će to vremenom izrasti u jedan od omiljenih kutaka ovog kraja. Iznenađenje za goste bio je ambijent lokala i zelenilo i uređenost oko lokala sa kompletnom izmenom zatečenog. Vremenom objekat se proširio na tom samom uglu Grbavice, dopunjavao se kako kapacitetom mesta, tako i infrastrukturno da bi danas postao baš to što jeste. Počelo je skromno na uglu Alekse Šantica, a onda se proširilo i duž Puškinove ulice. Uz skromni meni koji je postojao na početku svoj asortiman proširili smo i uneli dosta toga raznovrsnog kako bi svakom od naših gostiju mogli da ponudimo ono što želi, i danas se trudimo da pratimo moderan trend ugostiteljstva, sačuvamo recepturu naših obroka i modernizujemo njihovu prezentaciju",
    };
  },
  computed: {
    formattedText() {
      if (this.showingText) {
        return this.text;
      }
      return `${this.text.slice(0, 228).trim()}...`;
    },
  },
};
</script>

<style></style>
