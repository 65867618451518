<template>
  <div id="app">
    <Header />
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class=" animate__animated animate__fadeOut"
    >
      <router-view />
    </transition>
    <Footer />
    <ScrollUp />
  </div>
</template>
<script>
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import ScrollUp from "vue-scroll-up";
export default {
  components: {
    Header,
    Footer,
    ScrollUp,
  },
};
</script>

<style lang="scss">
@import "./assets/styles/index.scss";
@import "~bootstrap/scss/bootstrap";
#app {
  font-family: "PT Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Lora", serif;
  }
  h1 {
    padding: 30px;
  }
}
</style>
