<template>
  <b-navbar
    id="navbar"
    v-on-clickaway="clickedAway"
    toggleable="md"
    class="w-100"
  >
    <b-navbar-toggle target="collapse-area">
      <span class="when-opened">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </span>
      <span class="when-closed">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </span>
    </b-navbar-toggle>
    <b-collapse id="collapse-area" ref="collapse-area" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/">Početna</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="d-none d-md-block mx-auto">
        <b-nav-item to="/">
          <img
            class="logo-img"
            src="@/assets/images/velvet-logo.png"
            alt="Velvet Logo"
          />
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item to="/galerija">Galerija</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "Home",
  components: {},

  mixins: [clickaway],
  methods: {
    clickedAway() {
      if (this.$refs["collapse-area"].show)
        this.$root.$emit("bv::toggle::collapse", "collapse-area");
    },
  },
};
</script>
