<template>
  <div id="home">
    <img src="@/assets/images/interior/interior30.jpg" alt="" />

    <div class="logo-text d-xl-none d-lg-none d-md-none">
      <img src="@/assets/images/velvet-logo.png" alt="" />
    </div>
    <about />

    <menu-navbar />
    <!-- <cocktail-menu /> -->
    <drinks-slider />
    <div id="fresh">
      <h2>Svaki dan sveže namirnice</h2>
    </div>
    <image-slider />
  </div>
</template>

<script>
import About from "../components/home/About.vue";
import MenuNavbar from "../components/home/MenuNavbar.vue";
import ImageSlider from "../components/home/ImageSlider.vue";
// import CocktailMenu from "../components/home/CocktailMenu.vue";
import DrinksSlider from "../components/home/DrinksSlider.vue";

export default {
  name: "Home",
  components: {
    About,
    MenuNavbar,
    ImageSlider,
    // CocktailMenu,
    DrinksSlider,
  },

  data() {
    return {
      slides: [
        {
          image: require("@/assets/images/interior/image2.jpg"),
        },
        {
          image: require("@/assets/images/interior/image3.jpg"),
        },
      ],
    };
  },
};
</script>

<style></style>
